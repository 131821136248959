import ConstantTool from "@/services/tool/ConstantTool";
import Response from "@/models/responses/Response";
import SessionModule from "@/store/SessionModule";
import {getModule} from "vuex-module-decorators";
import JsonTool from "@/services/tool/JsonTool";
import User from "@/models/User";
import axios, {AxiosError} from "axios";
import SnackbarModule from "@/store/SnackbarModule";

/**Servicio para manejar los comerciales.
 *
 * Los comerciales internamente son solo usuarios con un rol en particular, sin embargo es necesario tenerlos en un servicio aparte.
 */
export default class CommercialService {

    static async getCommercial(id: number): Promise<Response<User>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + `/api/user/commercial/${id}`,{
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const commercial = JsonTool.jsonConvert.deserializeObject(response.data, User)
            return Promise.resolve({ result: commercial })
        } catch (e) { return Promise.reject(e) }
    }

    static async createCommercial(email: string, password: string, name: string, commercialName: string): Promise<Response<User>> {
        let formData = new FormData()
        formData.set("email", email)
        formData.set("password", password)
        formData.set("name", name)
        formData.set("commercialName", commercialName)

        try {
            const response = await axios.post(ConstantTool.BASE_URL + "/api/user/register/commercial", formData, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const commercial = JsonTool.jsonConvert.deserializeObject(response.data, User)
            return Promise.resolve({ result: commercial })
        } catch (e) { return Promise.reject(e) }
    }

    static async getMyCommercials(page: number, size: number, search: Nullable<string>): Promise<Response<User[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/@me/user/commercial", {
                headers: { Authorization: getModule(SessionModule).session.token },
                params: { page, size, search }
            })
            const commercial = JsonTool.jsonConvert.deserializeArray(response.data, User)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: commercial, xTotalCount})
        } catch (e) { return Promise.reject(e) }
    }

    static async patchCommercial(id: number, request: User) {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + `/api/@me/user/commercial/${id}/update`, request,{
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const commercial = JsonTool.jsonConvert.deserializeObject(response.data, User)
            return Promise.resolve({ result: commercial })
        } catch (e) { return Promise.reject(e) }
    }

    static async setActive(id: number, enabled: boolean): Promise<Response<User>> {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + `/api/@me/user/commercial/${id}/enabled`, null,{
                headers: { Authorization: getModule(SessionModule).session.token },
                params: { enabled }
            })
            const commercial = JsonTool.jsonConvert.deserializeObject(response.data, User)
            return Promise.resolve({ result: commercial })
        } catch (e) { return Promise.reject(e) }
    }

    static async patchCommercialCrmStatus(id: number, isCrmEnabled: boolean) {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + `/api/@me/user/commercial/${id}/crm`, null,{
                headers: { Authorization: getModule(SessionModule).session.token },
                params: { isCrmEnabled }
            })
            const commercial = JsonTool.jsonConvert.deserializeObject(response.data, User)
            return Promise.resolve({ result: commercial })
        } catch (e) { return Promise.reject(e) }
    }

    static async deleteCommercial(id: number) {
        try {
            const response = await axios.delete(ConstantTool.BASE_URL + `/api/@me/user/commercial/${id}`,{
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            if (response.status == 200) { getModule(SnackbarModule).makeToast("El comercial se eliminó exitosamente.") }
            return Promise.resolve({ result: null })
        } catch (e) {
            let error = e as AxiosError
            if (error.response?.data.message == "COMMERCIAL_HAS_CLIENTS") {
                getModule(SnackbarModule).makeToast("No se puede eliminar el comercial debido a la existencia de clientes asociados.")
            }
            return Promise.reject(e)
        }
    }
}